import NotificationsService from "@/services/notifications-service";
import {
	Paginated,
	ResultPaginate,
	SortingOption,
} from "@/interfaces/paginated";
import { catchError } from "@/store/Util";
import { isUndefined, orderBy } from "lodash";
import { getDefaultSortingOption, hasData } from "@/utils/filter-global";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import store from "..";

export const NotificationModule = {
	namespaced: true,
	state: () => ({
		loading: false,
		paginated: {
			page: 1,
			limit: 10,
		} as Paginated,
		options: getDefaultSortingOption("created_at"),
		notifications: {
			paginated: {} as ResultPaginate,
			settings: [] as any[],
		},
	}),
	mutations: {
		SET_LOADING(state: any, _loading = false) {
			state.loading = _loading;
		},
		SET_SETTINGS(state: any, result: any[]) {
			state.notifications.settings = result;
		},
		SET_PAGINATED(state: any, result: ResultPaginate) {
			if (isUndefined(result.data)) return;

			if (!hasData(state.notifications.paginated.data)) {
				state.notifications.paginated = result;
				return;
			}

			for (const item of result.data) {
				const index = state.notifications.paginated.data.findIndex(
					(m: any) => m.id == item.id
				);

				if (index > -1) {
					state.notifications.paginated.data[index] = item;
				} else {
					state.notifications.paginated.data.push(item);
					state.notifications.paginated.data = orderBy(state.notifications.paginated.data, "created", "desc");
				}
			}
		},
		DISMISS_MESSAGE(state: any, id: any) {
			const index = state.notifications.paginated.data.findIndex(
				(m: any) => m.id == id
			);
			if (index != -1) {
				state.notifications.paginated.data.splice(index, 1);
			}
		},
		iNCREMENT_PAGE(state: any) {
			state.paginated.page++;
		},
		DECREMENT_PAGE(state: any) {
			state.paginated.page--;
		},
		RESET_PAGE(state: any) {
			state.paginated.page = 1;
		},
		RESET_MESSAGES(state: any) {
			state.notifications.paginated = {} as ResultPaginate;
		},
	},
	getters: {
		isLoading(state: any) {
			return state.loading;
		},
		getResultPaginated(state: any) {
			return state.notifications.paginated;
		},
		getSettings(state: any) {
			return state.notifications.settings;
		},
		getPaginated(state: any) {
			return state.paginated;
		},
		getOptions(state: any) {
			return state.options;
		},
	},
	actions: {
		async incrementPage({ commit, state }: any) {
			return new Promise((resolve) => {
				commit("iNCREMENT_PAGE");
				resolve(state.paginated.page);
			});
		},
		async decrementPage({ commit, state }: any) {
			return new Promise((resolve) => {
				commit("DECREMENT_PAGE");
				resolve(state.paginated.page);
			});
		},
		async resetPage({ commit, state }: any) {
			return new Promise((resolve) => {
				commit("RESET_PAGE");
				resolve(state.paginated.page);
			});
		},
		async sendDismissNotification({ commit }: any, ids: string[]) {
			try {
				commit("SET_LOADING", true);
				const response = await NotificationsService.dismiss(ids);

				for (const id of ids) {
					await commit("DISMISS_MESSAGE", id);
				}

				commit("SET_LOADING", false);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async setActive(
			{ commit }: any,
			params: { 
				code: string; 
				platform_notification_active: Boolean; 
				email_notification_active: Boolean 
			}
		) {
			try {
				commit("SET_LOADING", true);

				const response = await NotificationsService.setActive(
					params.code,
					params.platform_notification_active,
					params.email_notification_active
				);

				notificationService.notifySuccess(this, {
					to: "",
				} as Notification);

				commit("SET_LOADING", false);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async fetchNotifications(
			{ commit },
			params: {
				paginated: Paginated;
				filters?: undefined;
				options?: SortingOption | undefined;
				isRefresh?: Boolean;
				enableLoading?: Boolean;
			}
		) {
			try {

				const isLoggedIn = store.getters["auth/isLoggedIn"];

				if(!isLoggedIn) {
					return;
				}

				if (params.enableLoading) {
					commit("SET_LOADING", true);
				}

				if (params.isRefresh) {
					await commit("RESET_PAGE");
				}

				const response = await NotificationsService.paginated(params);

				if (response.data.length < 1 && response.current_page > 1) {
					commit("DECREMENT_PAGE");
				}

				if (params.isRefresh) {
					await commit("RESET_MESSAGES");
				}

				await commit("SET_PAGINATED", response);

				if (params.enableLoading) {
					commit("SET_LOADING", false);
				}

				return await Promise.resolve(response);
			} catch (error) {
				commit("SET_LOADING", false);
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async fetchSettings({ commit }) {
			try {
				commit("SET_LOADING", true);

				const response = await NotificationsService.settings();

				await commit("SET_SETTINGS", response);

				commit("SET_LOADING", false);

				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async resetNotification({ commit }){
			try{
				commit("RESET_MESSAGES");
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		}
	},
};
