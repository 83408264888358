
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Dialog from "@/components/Content/Dialogs/Default.vue";
import CardActions from "@/components/Content/CardAction.vue";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";

import { TypeLoading } from "@/interfaces/loading";
import { SnackNotification, SnactType } from "@/interfaces/snack";
import { SnackNotificationEntity } from "@/models/Snack";
import { MARKET_PLACE_REF } from "@/models/persons/v10/DataDefault";

import SyncPostMessageMixin from "@/mixins/SyncPostMessage";

export default Vue.extend({
  name: "ConfirmRefresh",
  mixins: [SyncPostMessageMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    CardActions,
    SnackMessage,
  },
  data: () => ({
    snackData: new SnackNotificationEntity(),
  }),
  computed: {
    ...mapGetters("internationalization", ["getLanguage"]),
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("auth", ["ResetToken"]),

    async handleAction({ type }: { type: string }) {
      const ACTIONS = {
        submit: () => this.handleSubmit(),
        cancel: () => this.emitAction("cancel"),
      };

      if (ACTIONS[type]) ACTIONS[type]();
    },

    async handleSubmit() {
      try {
        await this.setLoadingData(TypeLoading.loading);
        await this.ResetToken();
        this.setSnackData({
          message: this.$t("snack.success.reset_token"),
          type: SnactType.SUCCESS,
        });
        this.dispatchMessageToMarketPlace();
      } catch (error) {
        this.setSnackData({
          message: this.$t("snack.error.reset_token"),
          type: SnactType.ERROR,
        });
      } finally {
        this.setLoadingData();
        this.emitAction("close");
      }
    },

    emitAction(action: "cancel" | "close") {
      this.$emit("action", action);
    },

    setSnackData(data: SnackNotification) {
      this.snackData.setSnackData(data);
    },

    resetSnackData() {
      this.snackData.setSnackData();
    },

    dispatchMessageToMarketPlace() {
      this.dispatchMessage({
        refName: MARKET_PLACE_REF,
        locale: this.getLanguage,
      });
    },
  },
});
