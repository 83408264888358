import { Component, Emit, Watch, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSelect from "@/components/Content/CardSelect.vue";
import PaginatedChips from "@/views/Admin/Reports/V2/Dependencies/Chips/PaginatedIndex.vue";
import DatePicker from "@/components/Content/DatePicker.vue";
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
import { isAfterCompare, isArrayRequired, isMaxDays, isRequired } from "@/services/rule-services";
import { getError } from "@/utils/resolveObjectArray";
import { ReportDataEntity, ResourceDataEntity } from "@/interfaces/report";
import { DEFAULT_DATE_TIME_FORMAT } from "@/utils/reportData";
import { isNull } from "lodash";
import { ReportFilterRule, ReportResourceDataEntity } from "@/models/Reports/v2/Report";
import { getPermisionReport } from "@/utils/permissionResolve";
import NotPermission from "@/views/Errors/not_permission.vue";
import { DATA_RANGE_CUSTOM } from "@/utils/report";
import { getFormattedDate } from "@/services/date-service";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";

@Component({
	components: {
		CardAutocomplete,
		CardSelect,
		PaginatedChips,
		DatePicker,
		DataPickerStarTime,
		NotPermission,
	},
	mixins: [],
})
export default class ReportFilters extends Vue {
	/**
	 * PROPS
	 */
	@Prop({ default: true }) public onSubmit!: boolean;

	/**
	 * GETTERS
	 */
	@Getter("report_v2/storeReport") getStoreReport!: ReportDataEntity;
	@Getter("report_v2/storeSchedule") getStoreSchedule!: ScheduleEntity;
	@Getter("report_v2/storeResources") getStoreResources!: ResourceDataEntity;
	@Getter("report_v2/storeResourceData")
	getStoreResourceData!: ReportResourceDataEntity;
	@Getter("account/isAccountMaster") isAccountMaster!: boolean;
	@Getter("profile/getAbility") getAbility;

	/**
	 * EMITTERS
	 */
	@Emit("all")
	handlerAll(key: string, value: any[]) {
		return { key, value };
	}

	@Emit("focus")
	handlerFocus(key: string) {
		return { key };
	}

	/**
	 * DATA
	 */
	public rulesEndDate: any[] = [];

	public formRules: ReportFilterRule = new ReportFilterRule();

	/**
	 * Computed
	 */
	public get modelAccount() {
		const account = this.getStoreReport.filters.account;
		if (this.isBilling) {
			return account;
		}
		return account ? account[0] : null;
	}

	public set modelAccount(value: any) {
		this.getStoreReport.filters.account = this.isBilling ? value : isNull(value) ? [] : [value];
	}

	get selectedAll() {
		return this.isBilling ? this.getStoreResourceData.selected_all.account : null;
	}

	public get isCustom(): boolean {
		return this.getStoreReport.isCustom();
	}

	public get isPreviusYear(): boolean {
		return this.getStoreReport.isPreviusYear();
	}

	public get isBilling(): boolean {
		return this.getStoreReport.isBilling();
	}

	public get isStoreAtributtion(): boolean {
		return this.getStoreReport.isSotreAtributtion();
	}

	public get isOoh(): boolean {
		return this.getStoreReport.isOoh();
	}

	public get isGeoCoordinates(): boolean {
		return this.getStoreReport.isGeoCoordinates();
	}

	public get getCurrentDateFormatted() {
		return this.$moment().format(DEFAULT_DATE_TIME_FORMAT);
	}

	public get getRangeFiltered() {
		let dataRange = this.getStoreResources.data_range;
		if (!this.isPreviusYear) return dataRange;
		return this.getStoreResources.data_range.filter(d => d.id === DATA_RANGE_CUSTOM);
	}

	public get getRules() {
		return {
			isRequired,
			isArrayRequired,
			isAfterCompare,
			isMaxDays,
		};
	}

	public get getPermission() {
		return getPermisionReport();
	}

	public get canAccountSP(): boolean {
		return this.getAbility.can(this.getPermission.actions["index_account_sp"], this.getPermission.subject);
	}

	public get canDataRangeSP(): boolean {
		return this.getAbility.can(this.getPermission.actions["index_data_sp"], this.getPermission.subject);
	}

	public get canFiltersSP(): boolean {
		return this.getAbility.can(this.getPermission.actions["show_filter_sp"], this.getPermission.subject);
	}

	public get getMinDate() {
		return getFormattedDate(
			this.getStoreReport.report_year,
			false
		);
	}

	public get getMaxDate() {
		return getFormattedDate(
			this.getStoreReport.report_year,
			true
		);
	}

	/**
	 * Actions
	 */

	public getError(index: "data_range" | "start_date" | "end_date") {
		return getError(this.getErrors, index);
	}

	private getErrors() {
		return this.$store.state.proccess.errors;
	}

	public handleFocus(key: string) {
		this.handlerFocus(key);
	}

	public handleRemove(event: any) {
		const { type, item } = event;

		if (type === "ooh_distance") {
			this.getStoreReport.filters.ooh_distance = null;
			return;
		}

		this.getStoreReport.removeFilter(type, item.key);
	}

	public handleSelectedAll(key: string, value: Array<any>) {
		this.handlerAll(key, value);
	}

	public showFilter(
		key:
			| "advertisers"
			| "campaigns"
			| "line_items"
			| "creatives"
			| "deal_id"
			| "layer_name"
			| "ooh_layer_name"
			| "ooh_distance",
	): boolean {
		let canFilter = this.canFiltersSP;
		canFilter = this.applyGeneralConditions(key, canFilter);
		canFilter = this.applySpecificConditions(key, canFilter);

		return canFilter;
	}

	private applyGeneralConditions(key: string, canFilter: boolean): boolean {
		const generalFilterKeys = ["advertisers", "campaigns", "line_items", "creatives", "deal_id"];
		if (generalFilterKeys.includes(key)) {
			canFilter = canFilter && !this.isOoh;
		}
		return canFilter;
	}

	private applySpecificConditions(key: string, canFilter: boolean): boolean {
		if (key === "creatives") {
			canFilter = canFilter && !this.isBilling;
		} else if (key === "layer_name") {
			canFilter = canFilter && (this.isStoreAtributtion || this.isOoh || this.isGeoCoordinates);
		} else if (["ooh_layer_name", "ooh_distance"].includes(key)) {
			canFilter = canFilter && this.isOoh;
		}
		return canFilter;
	}

	/**
	 * Actions Handlers
	 */

	/** Life Cicle */
	public async mounted() { }

	public created() {
		this.$nextTick(async () => { });
	}

	/**
	 * Verifica y actualiza las reglas de validacion para data_range
	 */
	public updateDataRangeRules() {
		this.formRules.addRule("data_range", [this.getRules.isArrayRequired]);
	}

	/**
	 * Verifica y actualiza las reglas de validacion para account
	 */
	public updateAccountRules() {
		if (this.isAccountMaster) {
			this.formRules.addRule(
				"account",
				this.isBilling ? [this.getRules.isArrayRequired] : [this.getRules.isRequired],
			);
		}
	}

	/**
	 * Verifica y actualiza las reglas de validacion para campaigns
	 */
	public updateCampaignsRules() {
		if (this.showFilter("campaigns")) {
			this.formRules.addRule("campaigns", [this.getRules.isArrayRequired]);
		}
	}

	/**
	 * Verifica y actualiza las reglas de validacion para end_date
	 */
	public updateEndDateRules() {
		const startDate: string | null | undefined = this.getStoreReport.start_date;
		const endDate: string | null | undefined = this.getStoreReport.end_date;
		let newRules: any[] = [];
		if (endDate && this.isCustom) {
			newRules = [this.getRules.isAfterCompare(endDate, startDate)];
		}
		this.formRules.addRule("end_date", newRules);
	}

	/**
	 * Verifica y actualiza las reglas de validacion para ooh
	 */
	public updateOohAttribRules() {
		if (this.isOoh || this.isStoreAtributtion || this.isGeoCoordinates) {
			this.formRules.addRule("layer_name", [this.getRules.isRequired]);
		}
		if (this.isOoh) {
			this.formRules.addRule("ooh_layer_name", [this.getRules.isRequired]);
			this.formRules.addRule("ooh_distance", [this.getRules.isRequired]);
		}
	}

	/**
	 * WATCHERS
	 */
	@Watch("getStoreReport.start_date")
	async onRulesStartDate(val?: string | null) {
		this.updateEndDateRules();
	}

	@Watch("getStoreReport.end_date")
	async onRulesEndDate(val?: string | null) {
		this.updateEndDateRules();
	}

	@Watch("onSubmit")
	async onSumbmit(val: boolean | null, old: boolean | null) {
		this.formRules = new ReportFilterRule();
		this.updateDataRangeRules();
		this.updateEndDateRules();
		this.updateAccountRules();
		this.updateCampaignsRules();
		this.updateOohAttribRules();

	}
}
