export async function setStyleSheets(response: any) {
	const styleSheets = Array.from(document.styleSheets).filter(
		(styleSheet) => {
			return (
				!styleSheet.href ||
				styleSheet.href.startsWith(window.location.origin)
			);
		}
	);

	for (let style of styleSheets) {
		if (style instanceof CSSStyleSheet && style.cssRules) {
			for (const rule of Object.entries(style.cssRules)) {
				if (rule[1]["selectorText"] == ":root") {
					//Imagenes
					rule[1]["style"].setProperty(
						"--image-login",
						"url(" + response.logo + ")"
					);
					rule[1]["style"].setProperty(
						"--image-lef-login",
						"url(" + response.logo + ")"
					);
					//Background Color
					rule[1]["style"].setProperty(
						"--background-color",
						response.background_color
					);
					//Primary and secondary color
					rule[1]["style"].setProperty(
						"--v-primary-base",
						response.input_color
					);
					rule[1]["style"].setProperty(
						"--v-secondary-base",
						response.button_color
					);
					rule[1]["style"].setProperty(
						"--title-color",
						response.titles_color
					);
					rule[1]["style"].setProperty(
						"--link-color",
						response.input_color
					);
					rule[1]["style"].setProperty(
						"--input-color-login",
						response.input_color
					);
					rule[1]["style"].setProperty(
						"--button-color-login",
						response.button_color
					);
					rule[1]["style"].setProperty(
						"--button-text-color-login",
						response.button_text_color
					);
					rule[1]["style"].setProperty(
						"--link-color-login",
						response.link_color
					);
					rule[1]["style"].setProperty(
						"--background-submenu",
						response.submenu_color_background
					);
					rule[1]["style"].setProperty(
						"--text-items-submenu",
						response.titles_color
					);
					rule[1]["style"].setProperty(
						"--text-items-hover-submenu",
						response.submenu_color_hover_text_item
					);
				}
			}
		}
	}
}

export async function setStyleProperty(params: { property: string, value: any}){
	const styleSheets = Array.from(document.styleSheets).filter(
		(styleSheet) => {
			return (
				!styleSheet.href ||
				styleSheet.href.startsWith(window.location.origin)
			);
		}
	);
	
	for (let style of styleSheets) {
		if (style instanceof CSSStyleSheet && style.cssRules) {
			for (const rule of Object.entries(style.cssRules)) {
				if (rule[1]["selectorText"] == ":root") {
					rule[1]["style"].setProperty(params.property, params.value);
				}
			}
		}
	}
}

export const dictionaryTextAction = {
	"menu.personsMenu.personsTitle": "planning",
	"menu.personsMenu.privatePOIsTitle": "privatePois",
	"menu.personsMenu.audienceMarketplace": "planning",
	"menu.advertiser": "advertiser",
	"menu.campaign": "campaign",
	"menu.lineItem": "lineItem",
	"menu.creative": "creative",
	"menu.reporting.title": "reporting",
	"menu.reporting.direct": "directReport",
	"menu.reporting.onDemand": "reportOnDemand",
	"menu.reporting.report_all_beta": "reportAllBeta",
	"menu.reporting.pdf_reports": "reportAllBeta",
	"menu.dashboard": "dashboard",
	"menu.requestCampaign": "campaignCpi",
	"menu.tools.title": "tools",
	"menu.tools.events": "event",
	"menu.tools.list": "list",
	"menu.tools.model": "model",
	"menu.tools.modifier": "modifier",
	"menu.tools.segment": "segmento",
	"oneLink.title": "oneLink",
	"menu.tools.user": "user",
	"menu.tools.lookalike": "index_lookalike",
	"menu.tools.segment_sub": "segmento",
	"menu.tools.segment_category": "segmentoCategory",
	"menu.tools.segment_upload": "segmentoUpload",
  };