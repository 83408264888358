import { decode, JwtPayload } from "jsonwebtoken";

// Constantes
const INVALID_TOKEN_MESSAGE = "El token es inválido o no contiene una propiedad de expiración.";
const BEARER_PREFIX_REGEX = /^Bearer\s+/i;

/**
 * Comprueba si el token tiene el prefijo "Bearer ".
 * @param token - El token JWT a verificar.
 * @returns `true` si el token tiene el prefijo "Bearer ".
 */
export function hasBearerPrefix(token: string): boolean {
  return token?.startsWith("Bearer ") ?? false;
}

/**
 * Elimina el prefijo "Bearer " del token.
 * @param token - El token JWT con el prefijo "Bearer ".
 * @returns El token sin el prefijo "Bearer ".
 */
export function removeBearerPrefix(token: string): string {
  return token?.replace(BEARER_PREFIX_REGEX, "") ?? "";
}

/**
 * Decodifica el token JWT y devuelve su payload.
 * @param token - El token JWT a decodificar.
 * @returns El payload del token o `null` si la decodificación falla.
 */
export function decodeTokenPayload(token: string): JwtPayload | null {
  const sanitizedToken = removeBearerPrefix(token);
  try {
    return decode(sanitizedToken, { json: true }) as JwtPayload | null;
  } catch (error) {
    console.error("Error al decodificar el token:", error);
    return null;
  }
}

/**
 * Verifica si el token ha expirado.
 * @param token - El token JWT a verificar.
 * @returns `true` si el token ha expirado, `false` en caso contrario.
 */
export function isTokenExpired(token: string): boolean {
  const payload = decodeTokenPayload(token);
  if (!payload?.exp) {
    console.warn(INVALID_TOKEN_MESSAGE);
    return true;
  }
  const currentTimeInSeconds = Math.floor(Date.now() / 1000); // Obtener tiempo actual en UTC
  const isTokenExpired = payload.exp < currentTimeInSeconds;

  if (isTokenExpired) {
    console.warn(`El token ha expirado.`);
  }

  return isTokenExpired;
}

/**
 * Verifica si el token está a punto de expirar dentro del umbral especificado (en minutos).
 * @param token - El token JWT a verificar.
 * @param thresholdInMinutes - Cantidad de minutos antes de la expiración para considerarlo "pronto a expirar".
 * @returns `true` si el token está a punto de expirar, `false` en caso contrario.
 */
export function isTokenExpiringSoon(token: string, thresholdInMinutes = 15): boolean {
  const payload = decodeTokenPayload(token);

  if (!payload?.exp) {
    console.warn(INVALID_TOKEN_MESSAGE);
    return true;
  }

  const currentTimeInSeconds = Math.floor(Date.now() / 1000); // Obtener tiempo actual en UTC
  const timeToExpirationInSeconds = payload.exp - currentTimeInSeconds;
  const thresholdInSeconds = thresholdInMinutes * 60;

  // Determinamos si el token está a punto de expirar
  const isExpiringSoon = timeToExpirationInSeconds < thresholdInSeconds;
  const statusMessage = isExpiringSoon ? "El token está a punto de expirar." : "El token es válido.";

  // Convertir el tiempo restante a minutos y segundos
  const remainingMinutes = Math.floor(timeToExpirationInSeconds / 60);
  const remainingSeconds = timeToExpirationInSeconds % 60;

  // Crear un mensaje de tiempo restante en formato "X minutos y Y segundos"
  const remainingTimeMessage = `${remainingMinutes} minutos y ${remainingSeconds} segundos`;

  console.warn(statusMessage, {
    "Tiempo restante para la expiracion": remainingTimeMessage,
    "Umbral (minutos)": Math.floor(thresholdInSeconds / 60),
  });

  return isExpiringSoon;
}

/**
 * Verifica si el token es válido (tiene el prefijo "Bearer " y no ha expirado).
 * @param token - El token JWT a validar.
 * @returns `true` si el token es válido, `false` en caso contrario.
 */
export function isTokenValid(token: string): boolean {
  return hasBearerPrefix(token) && !isTokenExpired(token);
}
