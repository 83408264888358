
/**
 * Limit de validez en segundos
 */
export const getValidityLimitSeg = Number(
	process.env.VUE_APP_URL_VALIDITY_LIMIT ?? 60
);

/**
 * Limit de validez convertido a minutos
 */
export const getValidityLimitMin = getValidityLimitSeg / 60;

/**
 * Umbral de expiracion del token en minutos
 */
export const getThresholdExpiration = Number(
	process.env.VUE_APP_THRESHOLD_EXPIRATION_MINUTES ?? 15
);
