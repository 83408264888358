
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { TypeLoading } from "@/interfaces/loading";
import { MARKET_PLACE_REF } from "@/models/persons/v10/DataDefault";
import { isTokenExpiringSoon } from "@/services/jwt-service";
import ConfirmRefreshToken from "@/views/Layouts/ConfirmRefreshToken.vue";
import SyncPostMessageMixin from "@/mixins/SyncPostMessage";
import { getThresholdExpiration } from "@/utils/env";

export default Vue.extend({
  name: "TokenManager",
  mixins: [SyncPostMessageMixin],
  components: {
    ConfirmRefreshToken,
  },
  data() {
    return {
      intervalId: null,
      isExpired: false,
      isExpiringSoon: false,
      intervalDelay: 20000, // Intervalo en milisegundos para validar el token
    };
  },

  async mounted() {
    this.$nextTick(() => {
      this.startTokenValidation();

      // solo para prueba local
      //this.simulateTokenExpiryForTesting();
    });
  },

  beforeDestroy() {
    this.clearTokenValidationInterval();
  },

  computed: {
    ...mapGetters("auth", ["getToken"]),
    ...mapGetters("internationalization", ["getLanguage"]),
  },

  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("auth", ["signOff"]),

    startTokenValidation() {
      this.validateToken(this.getToken);

      // Intervalo para validar el token cada 3 segundos (3000 ms)
      this.intervalId = setInterval(() => {
        this.validateToken(this.getToken);
      }, this.intervalDelay);
    },

    clearTokenValidationInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },

    simulateTokenExpiryForTesting() {
      // Intervalo para pruebas, expira el token a los 10 segundos
      setInterval(() => {
        this.isExpiringSoon = true;
      }, this.intervalDelay / 2);
    },

    validateToken(token: string) {
      const isValidToValidate = !this.isExpiringSoon && !!token;

      if (!isValidToValidate) return;

      // Si el token está por expirar (umbral de 15 minutos)
      this.isExpiringSoon = isTokenExpiringSoon(token, getThresholdExpiration);
    },

    showDialogToken(show: boolean) {
      this.isExpiringSoon = show;
    },

    async handleConfirmAction(action: "close" | "cancel") {
      if (action === "cancel") {
        await this.dispatchLogout();
      }
      this.showDialogToken(false);
    },

    async dispatchLogout() {
      await this.setLoadingData(TypeLoading.loading);
      await this.dispatchMessage({ refName: MARKET_PLACE_REF, locale: this.getLanguage });
      await this.signOff();
      this.setLoadingData(); // Reset loading state
    },
  },

  watch: {
    getToken: {
      immediate: true,
      handler(newToken: string) {
        this.validateToken(newToken);
      },
    },
  },
});
